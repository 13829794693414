import deepmerge from 'deepmerge';
import { NextSeo, NextSeoProps } from 'next-seo';
import { FC } from 'react';

import { getSeoImage, seoConfig } from '_config/next-seo.config';
import { usePublicAppContext } from '_core/context/public-app-context';

export type GenericPageSeoProps = {
  title: string;
  description: string;
  siteName?: string;
  image?: string;
  url?: string;
  canonical: string;
  nextSeoProps?: NextSeoProps;
};

/**
 * Build full seo tags from title, desc, canonical and url
 */
const buildSeoMeta = (pageProps: {
  title: string;
  description: string;
  image: string;
  siteName?: string;
  url?: string;
  canonical: string;
}): NextSeoProps => {
  const { title, description, image, canonical, siteName = seoConfig.genericPageSeo.siteName } = pageProps;
  return {
    title: title,
    canonical: canonical,
    openGraph: {
      site_name: siteName,
      type: 'website',
      title: title,
      description: description,
      images: [
        {
          url: image,
        },
      ],
    },
    additionalMetaTags: [
      {
        property: 'name',
        content: title,
      },
      {
        property: 'description',
        content: description,
      },
      {
        name: 'description',
        content: description,
      },
      {
        property: 'image',
        content: image,
      },
    ],
  };
};

export const GenericPageSeo: FC<GenericPageSeoProps & { children?: never }> = (props) => {
  const { title, description, image = getSeoImage('default'), siteName, canonical, nextSeoProps = {} } = props;

  const { domainInfo } = usePublicAppContext();
  const isProduction = domainInfo?.getEnvironment() === 'production';

  const noIndexNoFollow: Partial<NextSeoProps> = isProduction ? {} : { noindex: true, nofollow: true };

  const seoProps: NextSeoProps = deepmerge(buildSeoMeta({ title, description, image, canonical, siteName }), {
    ...nextSeoProps,
    ...noIndexNoFollow,
  });

  return <NextSeo {...seoProps} />;
};
